import { render, staticRenderFns } from "./adminDelete.vue?vue&type=template&id=4195ff03&scoped=true&"
import script from "./adminDelete.vue?vue&type=script&lang=js&"
export * from "./adminDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4195ff03",
  null
  
)

export default component.exports